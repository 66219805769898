<template>
    <div class="c-action-plan__table editable_table expandable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            :show-expand="activeRowId == null && currentNewId == null"
            :single-expand="true"
            item-key="vocationalEdId"
            :headers="headers"
            :items="tableDataArr"
            :expanded.sync="expanded"
            v-model="selected"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            :item-class="isNotRelevantClass"
            class="a-table w-full"
            data-cy="clientList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
            @item-selected="itemSelected"
        >
            <template v-slot:item.relevant="{ item }">
                <CheckboxCell
                    :ref="`relevant-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    :cell-id="item.vocationalEdId"
                    :disabled="disabled"
                    v-model="item.relevant"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.order="{ item }">
                <NumberInputCell
                    :ref="`order-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    :cell-id="item.vocationalEdId"
                    v-model="item.order"
                    :disabled="disabled"
                    :isRequired="true"
                    label="No"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.goal="{ item }">
                <SelectCell
                    :ref="`goal-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    :cell-id="item.vocationalEdId"
                     v-model="item.goal"
                    :disabled="disabled"
                    :items="defaultGoal"
                    :displayValue="getDisplayValue(item.goal, defaultGoal, 'text')"
                    :isRequired="true"
                    label="Goal"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.options="{ item }">
                 <VAutocompleteCell
                    :ref="`options-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    label="Options"
                    v-model="item.options"
                    :items="defaultOptions[item.goal]"
                    :cell-id="item.vocationalEdId"
                    :disabled="disabled"
                    :is-combobox="true"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.commenced="{ item }">
                <DatePickerCell
                    :ref="`commenced-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    v-model="item.commenced"
                    :cell-id="item.vocationalEdId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.completed="{ item }">
                <DatePickerCell
                    :ref="`completed-${item.vocationalEdId}`"
                    :edit-active="item.active"
                    v-model="item.completed"
                    :cell-id="item.vocationalEdId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
             <template v-slot:expanded-item="{ headers, item }" :class="{'selected': item.active}">
                <td colspan="2">&nbsp;</td>
                <td colspan="1" class="py-2">
                    <TextareaCell
                        :ref="`action-${item.vocationalEdId}`"
                        :edit-active="item.active"
                        :cell-id="item.vocationalEdId"
                        v-model="item.action"
                        :editable-component="'v-textarea'"
                        :disabled="disabled"
                        :isRequired="false"
                        label="Action"
                        @edit-row="toggleEdit"
                    />
                </td>
                <td colspan="4">&nbsp;</td>
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}">
                    <!-- TODO: Leaving Delete for admin later-->
<!--                    <a-->
<!--                        href="#"-->
<!--                        @click.prevent="deleteHandler(item)"-->
<!--                        v-if="!item.active"-->
<!--                        class="mx-1 bg-red-light"-->
<!--                    >-->
<!--                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />-->
<!--                    </a>-->
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange"/>
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        class="hover:text-primary bg-primary-lightest"
                        @click.prevent="saveHandler"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled && isAddable">
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addNewVocationalEd"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EditableCell from '@/components/partials/EditableCell'
import { FormRules } from '@/components/shared/mixins/formMixins'
import NumberInputCell from '@/components/partials/NumberInputCell'
import INSERT_VOCATIONAL_ED  from '@/graphql/mutations/insertVocationalEd.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import CheckboxCell from '@/components/partials/CheckboxCell'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import DatePickerCell from '@/components/partials/DatePickerCell'
import { format } from 'date-fns'
import { TableMethods } from '@/components/shared/mixins/sharedMixins'
import TextareaCell from '@/components/partials/TextareaCell'
import { SharedMixin } from '@/helpers/displayValue'
import SelectCell from '@/components/partials/SelectCell'
import vocationalEdGoalChoices from '@/json/vocationalEdGoalChoices.json'
import VAutocompleteCell from '@/components/partials/VAutocompleteCell'

export default {
    name: 'VocEdGoalsTable',
    components: { DatePickerCell, CheckboxCell, NumberInputCell, EditableCell, TextareaCell, SelectCell, VAutocompleteCell},
    mixins: [ FormRules, MessageDialog, TableMethods, SharedMixin ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        users: {
            type: Array,
            default: () => []
        },
        episode: {
            type: Object,
            default: () => {}
        },
        isAddable: {
            type: Boolean,
            default: true
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No vocational ed found',
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 20,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            currentTableRow: null,
            activeRowObj: null,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                { text: 'Current?', align: 'left', value: 'relevant', class: 'relevant--header w-50' },
                { text: 'No', align: 'left', value: 'order', class: 'order--header w-50' },
                { text: 'Goal', align: 'left', value: 'goal', class: 'goal--header' },
                { text: 'Options', align: 'left', value: 'options', class: 'options--header' },
                { text: 'Date Commenced', align: 'left', value: 'commenced', class: 'w-130 commenced--header' },
                { text: 'Date Completed', align: 'left', value: 'completed', class: 'w-130 completed--header' },
                { text: '', align: 'left', value: 'editRecord'},
                { text: '', value: 'data-table-expand' },
            ],
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                'order',
                'goal',
                'options',
                'commenced',
                'completed',
                'action'
            ],
            requiredFields: [
                'order',
                'goal',
            ],
            defaultNewObject: {
                relevant: true,
                order: null,
                goal: '',
                options:'',
                commenced: format(new Date(), 'yyyy-MM-dd'),
                completed: null,
                episodeId: this.episode.episodeId,
            },
            defaultGoal: [{
                text: 'Education',
                value: 'Education'
            }, {
                text: 'Employment',
                value: 'Employment'
            }, {
                text: 'Licence',
                value: 'Licence'
            }, {
                text: 'Certification',
                value: 'Certification'
            }, {
                text: 'Financial',
                value: 'Financial'
            }, {
                text: 'Health, Housing & Relationships',
                value: 'Health, Housing & Relationships'
            }],
            defaultOptions: {...vocationalEdGoalChoices},
            expanded: [],
            selected: [],
            editCancelled: false,
            addingNewRow: false
        }
    },
    methods: {
        isNotRelevantClass(item) {
            return item.relevant ? '' : 'no-longer-relevant'
        },
        rowClickHandler(obj, e) {
            // if we just cancelled the edit of the row then reset and cancel the edit
            if (this.editCancelled || this.currentNewId != null || this.activeRowId != null || !this.isEditable) {
                this.editCancelled = false
                return
            } else if (this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }

            e.select(true)
            this.currentTableRow = e
        },
        async addNewVocationalEd() {
            if (this.activeRowId) {
                const result = await this.validateInputs(this.activeRowId)
                if (result && result.includes(false)) {
                    this.showDiscardMessage(true)
                    return false
                }
            }
            this.addingNewRow = true
            await this.addNewRow('vocationalEdId', this.saveHandler)
            this.selection = [this.activeRowObj]
        },
        toggleEdit(val) {
            if (
                this.activeRowId && Number(this.activeRowId) === Number(val) ||
                this.currentNewId && Number(this.currentNewId) === Number(val) ||
                !this.isEditable
            ) return

            if (this.currentNewId ) {
                this.editCancelled = true
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.editCancelled = true
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
            if (this.activeRowObj) this.expanded = [this.activeRowObj]
            else this.expanded = []
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            const fields = this.refsToCheck
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj || !this.activeRowObj) return false

            return fields.filter(field => this.activeRowObj[field] !== changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.vocationalEdId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.requiredFields.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        async saveHandler() {
          try {
            const values = await this.validateInputs(this.activeRowId)
            let isSuccess = true
            values.forEach(value => { if(!value) isSuccess = false })
            if (!isSuccess) return
            // Post back to db
            // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

            const data = {...this.getActiveRow(this.tableDataArr)}
            const idx = this.tableDataArr.findIndex(t => t.vocationalEdId === data.vocationalEdId)

            if (data && data.typename) delete data.typename
            if (this.currentNewId && this.currentNewId === this.activeRowId) {
                data.userId = this.loggedInUser.userId
                delete data.vocationalEdId
            }
            if (!data.episodeId) data.episodeId = this.episode.episodeId
            if (data.options.text) data.options = data.options.text

            delete data.active
            data.timeFrame = ''
            data.rating = ''
            const convertedData = snakecaseKeys(data, {deep: true})
            await this.$apollo.mutate({
                mutation: INSERT_VOCATIONAL_ED,
                variables: {
                    vocationalEd: convertedData
                }
            }).then(({ data: { vocationalEd } }) => {
                if (vocationalEd) {
                    this.tableDataArr[idx] = {...camelcaseKeys(vocationalEd, {deep: true})}
                }

                this.saving = false
                this.message = 'Saved vocational ed item'
                this.type = 'success'

                this.clearRowSelection()

                this.$emit('vocational-ed-saved')
            }).catch(error => {
                this.message = 'Failed saving vocational ed'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                data.active = true
                this.showMessage({ duration: 5000 })
            })
          } catch(err) {
              console.error('validation failed: ', err)
          }
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.vocationalEdId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.vocationalEdId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.vocationalEdId !== this.activeRowId) return item
                })
            }
        },
        cancelHandler() {
            this.editCancelled = true

            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            if (this.currentTableRow) this.currentTableRow.select(false)
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
            this.currentRow = null
            this.currentTableRow = null
            this.selected = []
        },
        itemSelected({ item, value }) {
            if (value) {
                this.currentRow = item
                this.currentTableRow = item
                this.selected = [this.currentRow]
                this.expanded = [this.currentRow]
            }
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices
        }),
    },
    watch: {
        tableData() {
            let emptyRow
            if (this.addingNewRow && this.currentNewId) emptyRow = this.tableDataArr.find(tda => tda.vocationalEdId === this.currentNewId)
            this.tableDataArr = this.tableData

            // if (this.tableDataArr && this.tableDataArr.length === 0) this.addNewVocationalEd()
            if (emptyRow && !this.tableData.find(tda => tda.vocationalEdId === this.currentNewId)) {
                this.addingNewRow = false
                this.tableDataArr.push(emptyRow)
            }
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        },
        tableDataArr: {
            handler() {
                this.$emit('table-data-change', this.tableDataArr)
            },
            deep: true
        },
        episode() {
            this.cancelHandler()
            this.defaultNewObject.episodeId = this.episode.episodeId
        }
    }
}
</script>
