import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2, REST_API } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class EpisodeService {
    constructor() {
        this.episodesUrl = `${APP_API_V2}/episodes`
    }

    async getVocationalEdForEpisode(episodeId, relevant) {
        let url = `${this.episodesUrl}/${episodeId}/voc-ed-goals`
        if (relevant) url += '?relevant=true'
        const { data } = await axiosInstance.get(
            url,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(data)
    }

    async getActionPlansForEpisode(episodeId, relevant) {
        const { data } = await axiosInstance.get(
            `${this.episodesUrl}/${episodeId}/action-plans?relevant=${relevant}`,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(data)
    }

    async searchClients(searchTerm, offset) {
        let url = `${this.clientsUrl}?q=${searchTerm}`
        if (offset !== null) {
            url += `&page=${offset}`
        }
        console.log('====== url', url)
        const { data } = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        const { page, pages, items } = data
        return { clients: camelcaseKeys(items), page, pages }
    }
}

export const episodeService = new EpisodeService()
