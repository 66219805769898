<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged"
            />
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <div class="flex flex-row justify-between pb-4 items-center">
                <h4 class="font-display font-semibold text-left text-lg">Goals</h4>
            </div>
            <div class="flex items-center mb-4">
                <div class="mr-2">
                    <fa-icon icon="filter" class="text-sm text-black" />
                </div>
                <v-checkbox
                    hide-details
                    class="mt-0 mr-4"
                    :ripple="false"
                    v-model="showCurrent"
                    label="Current Goals"
                    @change="clearFilters"
                ></v-checkbox>
            </div>
            <VocEdGoalsTable
                :tableData="vocEdGoals"
                :users="users"
                :episode="computedEpisode"
                :isAddable="$can('add', 'action_plan')"
                :isEditable="$can('edit', 'action_plan')"
                @table-data-change="tableDataUpdated"
                @vocational-ed-saved="getVocEdGoals"
            />
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { computed, watch, reactive, toRefs, onMounted } from '@vue/composition-api'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import VocEdGoalsTable from '@/components/voc-ed-goals/partial/VocEdGoalsTable.vue'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import { episodeService } from '@/services/episode.service'

const { useActions } = createNamespacedHelpers('')
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'VocEdGoals',
    components: { VocEdGoalsTable, EpisodeHeader },
    mixins: [FileMethods, MessageDialog],
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                const openEpisodes = episodes.filter((e) => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter((e) => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], { deep: true })
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, { deep: true })
            }
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            episodes: [],
            vocationalEd: {},
            users: [],
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
    },
    watch: {
        '$route.path': function (val, oldVal) {
            this.$apollo.queries.client.refetch()
        }
    },
    setup() {
        const state = reactive({
            loading: false,
            vocEdGoals: [],
            showCurrent: true,
            episode: {},
            defaultVocationalEd: {
                order: null,
                goal: null,
                action: null,
                options: null,
                commenced: null,
                completed: null,
                rating: null,
                timeFrame: null
            }
        })

        const { setCurrentEpisode } = useActions(['setCurrentEpisode'])
        const { currentEpisode } = useState(['currentEpisode'])

        // TODO: remove below as it seems like it's not being used
        const createVocationalEd = () => {
            const vocationalEd = { ...state.defaultVocationalEd }
            state.vocEdGoals = [...state.vocEdGoals, { ...vocationalEd }]
        }

        const computedEpisode = computed(() => {
            return currentEpisode.value || state.episode
        })
        const episodeChanged = async (episode) => {
            // return
            console.log('====== setting current episode')

            await setCurrentEpisode(episode)
            state.episode = { ...episode }
        }
        const tableDataUpdated = (vocationalEdGoals) => {
            state.vocEdGoals = vocationalEdGoals
        }
        const clearFilters = async () => {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!state.showCurrent) state.showCurrent = null
            await getVocEdGoals()
        }
        const getVocEdGoals = async () => {
            try {
                const data = await episodeService.getVocationalEdForEpisode(
                    currentEpisode.value.episodeId,
                    state.showCurrent
                )
                if (data.length > 0) state.vocEdGoals = [...data]
            } catch (error) {
                console.error(error)
            } finally {
                state.loading = false
            }
        }

        watch(currentEpisode, async (updatedEpisode, oldEpisode) => {
            console.log('episode changed to', updatedEpisode, updatedEpisode.episodeId === oldEpisode.episodeId)
            if (updatedEpisode.episodeId === oldEpisode.episodeId) return
            state.loading = true
            await getVocEdGoals()
        })

        onMounted(async () => {
            if (!currentEpisode.value?.episodeId) return
            state.loading = true
            await getVocEdGoals()
        })

        return {
            ...toRefs(state),
            computedEpisode,
            clearFilters,
            getVocEdGoals,
            tableDataUpdated,
            currentEpisode,
            episodeChanged,
            createVocationalEd
        }
    }
}
</script>
